import localforage from "localforage";
import { runConfig, version } from "../package.json";

localforage.config({
  version: version,
  storeName: "store" + version,
});

export const set = (key, value) => {
  return localforage.setItem(key, value);
};

export const get = (key) => {
  return localforage.getItem(key)
    .then(result => {
      return result;
    });
};

export const remove = (key) => {
  return localforage.removeItem(key);
};

export const setJSON = (key, value) => {
  return set(key, JSON.stringify(value));
};

export const getJSON = (key) => {
  return get(key)
    .then(value => {
      return JSON.parse(value);
    })
    .catch(() => {
      return {};
    });
};

export const getCredentials = async () => {
  return {
    token: await get("token"),
    api: runConfig.api,
  };
};
