import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import sha512 from "crypto-js/sha512";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";


const EditableContext = React.createContext();

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  customtype,
  customdata,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (customtype === "date") {
      let value = null;

      if (editing === false) {
        if (record[dataIndex]) {
          value = moment(record[dataIndex]);
        } else {
          value = "";
        }
      } else {
        value = record[dataIndex];
      }

      form.setFieldsValue({
        [dataIndex]: value,
      });
    } else {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }

    setEditing(!editing);
  };

  const save = async e => {

    try {
      const values = await form.validateFields();
      toggleEdit();
      Object.keys(values).forEach(key => {
        if (key === "dBegin" || key === "dEnd") {
          values[key] = moment(values[key]).toISOString(true);
        }
      });
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = (editing && customtype !== "password") ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        {customtype === "select" ? (
          <Select ref={inputRef} onBlur={save} onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}>
            {customdata.map(entry => {
              return <Select.Option key={entry.rid} value={entry.rid}>{entry.title}</Select.Option>;
            })}
          </Select>
        ) : (
          customtype === "date" ? (
            <DatePicker ref={inputRef} onPressEnter={save} onBlur={save} format="YYYY-MM-DD" />
          ) :
            customtype === "number" ? (
              <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={-999999} max={999999} />
            ) : (
              <Input ref={inputRef} onPressEnter={save} onBlur={save} onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }} />
            )
        )}
      </Form.Item >
    ) : (<div
      className="editable-cell-value-wrap"
      style={{
        paddingRight: 24,
      }}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        if (customtype === "password") {
          const newPassword = prompt("Введите новый пароль");

          if (newPassword) {
            handleSave({ ...record, ...{ [dataIndex]: sha512(newPassword).toString() } });
          }
        } else {
          toggleEdit(event);
        }
      }}
    >
      {children}
    </div>);
  }

  return <td {...restProps}>{childNode}</td>;
};
