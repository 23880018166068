import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { MenuBar } from "../components/MenuBar";
import { get } from "../storage";
import { Clients } from "./Clients";
import { History } from "./History";
import { Login } from "./Login";


function PrivateRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => user?.SLRid
        ? <><MenuBar /><Component {...props} /></>
        : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />}
    />
  );
}

export class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loaded: false,
    };
  }

  async componentDidMount() {
    const SLRid = await get("SLRid");
    if (SLRid) {
      this.setState({
        user: { SLRid },
        loaded: true,
      });
    } else {
      this.setState({ loaded: true });
    }
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} user={this.state.user} />
          <PrivateRoute exact path="/" component={Clients} user={this.state.user} />
          <PrivateRoute exact path="/history" component={History} user={this.state.user} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}