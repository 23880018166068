import { Button, PageHeader as AntDPageHeader } from "antd";
import React from "react";
import { remove } from "../storage";

export const PageHeader = ({title}) => {
  return <AntDPageHeader
    title={title}
    extra={[
      <Button key="1" onClick={async () => {
        if (window.confirm("Выйти?")) {
          await remove("token");
          await remove("SLRid");
          window.location.reload();
        }
      }}>Выйти</Button>,
    ]}
  />;
};
