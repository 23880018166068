import { Select } from "antd";
import React from "react";

export class SelectAll extends React.Component {
  handleChange(value) {
    console.log(1, value, this.props.feature);
    if (value.includes("all")) {
      this.props.onChange && this.props.onChange(Object.keys(this.props.feature).map(entry => +entry));
    } else {
      this.props.onChange && this.props.onChange(value);
    }
  }

  render() {
    console.log("VALUE", this.props.value);

    return (
      <Select
        mode="multiple"
        value={this.props.value}
        onChange={this.handleChange.bind(this)}
      >
        <Select.Option key={"all"} value="all">Выбрать всё</Select.Option>
        {Object.keys(this.props.feature).map(key => {
          const entry = this.props.feature[key];
          return <Select.Option key={entry.rid} value={entry.rid}>{entry.title}</Select.Option>;
        })}
      </Select>
    );
  }
}
