import { Button, Popconfirm, Table } from "antd";
import React from "react";
import { EditableCell, EditableRow } from "./EditableTable";

export class LoginView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [...props.list],
    };
  }

  save = (editRow = null) => {
    let list = this.state.list;

    if (editRow) {
      list = list.filter((entry) => entry.login === editRow);
    }

    this.props.save(list);
  };

  handleSave = (row) => {
    const list = [...this.props.list];
    const index = list.findIndex((item) => row.login === item.login);
    console.log("INDEX", index);
    const item = list[index];
    list.splice(index, 1, { ...item, ...row });

    this.setState({ editRow: row.login, list });

    //this.save(list);
  };

  handleDelete = (login) => {
    const list = this.props.list.map(item => ({...item, _rowstate: (item.login === login ? "d": "s")}));
    this.props.save(list);
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
        row: EditableRow,
      },
    };

    const columns = [
      {
        title: "Логин",
        dataIndex: "login",
        key: "login",
        ellipsis: true,
        width: "20%",
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
        ellipsis: true,
        editable: true,
        render: (text) => (text ? text : <>&nbsp;</>),
      },
      {
        title: "Пароль",
        dataIndex: "pwdH",
        key: "pwdH",
        ellipsis: true,
        editable: true,
        customtype: "password",
        render: () => "Пароль скрыт",
      },
      {
        title: "Наименование",
        dataIndex: "title",
        key: "title",
        ellipsis: true,
        editable: true,
      },
      {
        title: "Примечание",
        dataIndex: "note",
        key: "note",
        ellipsis: true,
        editable: true,
        render: (text) => (text ? text : <>&nbsp;</>),
      },
      {
        title: "",
        key: "operation1",
        render: (text, record) => (
          <Button
            type="primary"
            disabled={this.state.editRow !== record.login}
            onClick={() => {
              this.save(record.login);
            }}
          >
            Сохранить
          </Button>
        ),
      },
      {
        title: "",
        key: "operation2",
        width: "20%",
        render: (text, record) => (
          <Popconfirm
            title="Подтвердите удаление"
            onConfirm={() => this.handleDelete(record.login)}
          >
            <Button type="link">Удалить</Button>
          </Popconfirm>
        ),
      },
    ].map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          customtype: col.customtype,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <>
        <Table
          rowClassName={() => "editable-row"}
          bordered
          title={() => this.props.currentClient?.title}
          components={components}
          rowKey="login"
          pagination={false}
          dataSource={this.state.list}
          columns={columns}
        />
      </>
    );
  }
}
