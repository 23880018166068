import { Button, message, Modal, Popconfirm, Table, Typography } from "antd";
import React from "react";
import { v4 as uuid } from "uuid";
import { api } from "../api";
import { ClientView } from "../components/ClientView";
import { EditableCell, EditableRow } from "../components/EditableTable";
import { LoginView } from "../components/LoginView";
import { get } from "../storage";
import sha512 from "crypto-js/sha512";

export class Clients extends React.Component {
  state = {
    loaded: false,
    currentClient: null,
  };

  handleSave = (row) => {
    const newData = [...this.state.client];
    const index = newData.findIndex((item) => row.rid === item.rid);
    console.log("INDEX", index);
    const item = {...newData[index], _rowstate: "s"};
    newData.splice(index, 1, { ...item, ...row });
    this.setState(
      {
        client: newData,
        currentClient: item,
      },
      () => {
        this.save();
      }
    );
  };

  handleDelete = (rid) => {
    const dataSource = [...this.state.client];
    const clients = dataSource.map(item => ({...item, _rowstate: (item.rid === rid ? "d": "s")}));
    const client = dataSource.filter((item) => item.rid === rid);

    this.setState({ client: clients, currentClient: client[0] }, () => {
      this.save();
    });
  };

  getList = async () => {
    const response = await api.call("runMobile_ClientList", {
      SLRid: await get("SLRid"),
    });

    if (response) {
      const feature = {};
      response.feature.forEach((element) => {
        feature[element.rid] = element;
      });

      this.setState({
        client: response.client,
        clientFeature: response.clientFeature,
        clientState: response.clientState,
        clientLogin: response.clientLogin,
        feature,
        loaded: true,
        lastUpdate: new Date().toISOString(),
      });
    }
  };

  componentDidMount() {
    this.getList();
  }

  addClient = () => {
    const client = [...this.state.client];
    const newClient = {
      rid: uuid().toUpperCase(),
      note: "",
      stateRid: 1,
      title: "Новый клиент " + client.length++,
      _rowstate: 'a',
    };

    client.push(newClient);

    this.setState({ client, currentClient: newClient }, () => {
      this.save();
    });
  };

  save = async () => {
    const response = await api.call("runMobile_ClientSave", {
      SLRid: await get("SLRid"),
      ClientRid: this.state.currentClient.rid,
      client: this.state.client.filter(
        (entry) => entry.rid === this.state.currentClient.rid
      ).map(entry => ({...entry, _rowstate: entry._rowstate || "s"})),
      clientFeature: this.state.clientFeature.filter(
        (entry) => entry.clientRid === this.state.currentClient.rid
      ).map(entry => ({...entry, _rowstate: entry._rowstate ||  "s"})),
      clientLogin: this.state.clientLogin.filter(
        (entry) => entry.clientRid === this.state.currentClient.rid
      ).map(entry => ({...entry, _rowstate: entry._rowstate ||  "s"})),
    });

    if (response?.validateResults?.length) {
      response.validateResults.forEach((entry) => {
        if (entry.level === 1) {
          message.info(entry.text, 3);
        } else if (entry.level === 2) {
          message.warning(entry.text, 3);
        } else {
          message.error(entry.text, 3);
        }
      });
    }

    this.getList();

    console.log("SAVE", this.state.clientFeature);
  };

  render() {
    if (!this.state.loaded) {
      return null;
    }

    const components = {
      body: {
        cell: EditableCell,
        row: EditableRow,
      },
    };

    const columns = [
      {
        title: "",
        key: "operation",
        render: (text, record) => (
          <Button
            type="link"
            onClick={(event) => {
              event.stopPropagation();
              this.setState({ currentClient: record, isClientOpen: true });
            }}
          >
            Лицензии
          </Button>
        ),
      },
      {
        title: "",
        key: "operation2",
        render: (text, record) => (
          <Button
            type="link"
            onClick={(event) => {
              event.stopPropagation();
              this.setState({ currentClient: record, isLoginOpen: true });
            }}
          >
            Логины
          </Button>
        ),
      },
      {
        title: "Наименование клиента",
        dataIndex: "title",
        key: "title",
        editable: true,
        width: "25%",
      },
      {
        title: "Ключ клиента",
        dataIndex: "rid",
        key: "rid",
        width: "25%",
        render: (text) => (
          <Typography.Paragraph
            copyable
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {text}
          </Typography.Paragraph>
        ),
      },
      {
        title: "Статус",
        dataIndex: "stateRid",
        key: "stateRid",
        editable: true,
        customtype: "select",
        customdata: this.state.clientState,
        width: "25%",
        render: (text, record) =>
          this.state.clientState.filter((entry) => entry.rid === text)[0].title,
      },
      {
        title: "Примечание",
        dataIndex: "note",
        key: "note",
        editable: true,
        render: (text) => (text ? text : <>&nbsp;</>),
        width: "25%",
      },
      {
        title: "",
        key: "operation",
        render: (text, record) => (
          <Popconfirm
            title="Подтвердите удаление"
            onConfirm={(event) => {
              event.stopPropagation();
              this.handleDelete(record.rid);
            }}
          >
            <Button
              type="link"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              Удалить
            </Button>
          </Popconfirm>
        ),
      },
    ].map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          customtype: col.customtype,
          customdata: col.customdata,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <>
        <Modal
          className="my-modal-class"
          width="100%"
          maskClosable={false}
          closable={false}
          header={null}
          visible={!!this.state.isLoginOpen}
          onOk={() => {
            this.setState({ currentClient: null, isLoginOpen: false });
          }}
          onCancel={() => {
            this.setState({ currentClient: null, isLoginOpen: false });
          }}
          footer={[
            <Button
              key="add"
              type="primary"
              onClick={() => {
                const list = this.state.clientLogin.filter(
                  (entry) => entry.clientRid === this.state.currentClient.rid
                );
                // do modal
                const newlogin = prompt(
                  "Введите Login",
                  "newLogin" + list.length++
                );

                if (newlogin) {
                  const newItem = {
                    clientRid: this.state.currentClient.rid,
                    login: newlogin,
                    note: "",
                    pwdH: sha512("").toString(),
                    title: "Клиент1",
                    _rowstate: "a",
                  };

                  list.push(newItem);
                  const currentClient = this.state.client.filter(
                    (entry) => entry.rid === this.state.currentClient.rid
                  )[0];

                  this.setState(
                    {
                      clientLogin: list,
                      currentClient,
                      lastUpdate: new Date().toISOString(),
                    },
                    () => {
                      this.save();
                    }
                  );
                }
              }}
            >
              Добавить логин
            </Button>,
            <Button
              key="back"
              onClick={() => {
                this.setState({ currentClient: null, isLoginOpen: false });
              }}
            >
              Закрыть
            </Button>,
          ]}
        >
          {this.state.isLoginOpen ? (
            <LoginView
              client={this.state.currentClient}
              list={this.state.clientLogin.filter(
                (entry) => entry.clientRid === this.state.currentClient.rid
              )}
              currentClient={this.state.currentClient}
              key={this.state.lastUpdate}
              save={(clientLogin) => {
                const currentClient = this.state.client.filter(
                  (entry) => entry.rid === this.state.currentClient.rid
                )[0];

                this.setState({ clientLogin, currentClient }, () => {
                  this.save();
                });
              }}
            />
          ) : null}
        </Modal>

        <Modal
          className="my-modal-class"
          width="100%"
          maskClosable={false}
          closable={false}
          header={null}
          visible={!!this.state.isClientOpen}
          onOk={() => {
            this.setState({ currentClient: null, isClientOpen: false });
          }}
          onCancel={() => {
            this.setState({ currentClient: null, isClientOpen: false });
          }}
          footer={[
            <Button
              key="add"
              type="primary"
              onClick={() => {
                this.setState({
                  isClientAddOpen: true,
                  lastUpdate: new Date().toISOString(),
                });
              }}
            >
              Добавить лицензию
            </Button>,
            <Button
              key="back"
              onClick={() => {
                this.setState({ currentClient: null, isClientOpen: false });
              }}
            >
              Закрыть
            </Button>,
          ]}
        >
          {this.state.isClientOpen ? (
            <ClientView
              isClientAddOpen={!!this.state.isClientAddOpen}
              isClientAddOpenChange={(isOpen) => {
                this.setState({
                  isClientAddOpen: isOpen,
                  lastUpdate: new Date().toISOString(),
                });
              }}
              feature={this.state.feature}
              clientFeature={this.state.clientFeature}
              currentClient={this.state.currentClient}
              key={this.state.lastUpdate}
              save={(clientFeature) => {
                this.setState({ clientFeature }, () => {
                  this.save();
                });
              }}
            />
          ) : null}
        </Modal>

        <div
          style={{ padding: 10, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            type="primary"
            onClick={() => {
              this.addClient();
            }}
          >
            Добавить клиента
          </Button>
        </div>

        <Table
          rowClassName={() => "editable-row"}
          bordered
          components={components}
          rowKey="rid"
          pagination={false}
          dataSource={this.state.client}
          columns={columns}
        />
      </>
    );
  }
}
