import { Button, Form, Input, Row } from "antd";
import sha512 from "crypto-js/sha512";
import React from "react";
import { api } from "../api";
import { set } from "../storage";

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 12,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 10,
    span: 12,
  },
};

export const Login = (props) => {
  const checkApi = async (username, password) => {
    const response = await api.call("runMobile_CheckLogin", {
      Login: username,
      PwdH: sha512(password).toString(),
    });

    if (response?.results?.length) {
      const { SLRid } = response.results[0];
      await set("SLRid", SLRid);

      return true;
    }

    return false;
  };

  const onFinish = async values => {
    console.log("Success:", values);
    const { username, password } = values;
    if (await checkApi(username, password)) {
      // redirect on root
      console.log("OK!!!!", props);
      window.location.href = "/";
    } else {
      alert("Неправильное имя или пароль");
    }
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row type="flex" justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <h2>Админка</h2>
        <Form.Item
          label="Пользователь"
          name="username"
          rules={[
            {
              required: true,
              message: "Пожалуйста введите имя пользователя",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
        /* rules={[
          {
            required: true,
            message: "Пожалуйста введите пароль",
          },
        ]} */
        >
          <Input.Password />
        </Form.Item>


        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};