import axios from "axios";
import { getCredentials, set } from "./storage";

const successMiddleware = async (response) => {
  if (response.data.__api_errors && response.data.__api_errors.length) {
    const errors = response.data.__api_errors;
    let hasAuthError = false;

    errors.map(entry => {
      if (entry.source === "authenticate" || entry.source === "authorize") {
        hasAuthError = true;
      }
      return null;
    });

    if (hasAuthError) {
      await set("token", null);
      await set("SLRid", null);
      window.location.reload();
    }

    return { validateResults: errors };
  } else {
    if (response?.data?.validateResults?.length) {
      const errors = response.data.validateResults;
      let hasAuthError = false;
      errors.map(entry => {
        if (entry.spName === "authenticate" || entry.spName === "authorize") {
          hasAuthError = true;
        }
        return null;
      });

      if (hasAuthError) {
        await set("token", null);
        await set("SLRid", null);
        window.location.reload();
      }
    }

    return response.data;
  }
};

export const api = {
  refreshToken: async () => {
    const credentials = await getCredentials();
    const requestData = {
      method: "post",
      url: credentials.api,
      data: JSON.stringify({
        "subject": "authorize",
        "username": "user",
        "password": "1234",
      }),
    };

    const response = await axios(requestData);

    if (response?.data?.token) {
      await set("token", response.data.token);
      return response.data.token;
    } else {
      return null;
    }
  },
  call: async (actionName, params = {}) => {
    const callReal = async () => {
      const credentials = await getCredentials();
      const requestData = JSON.stringify({
        "token": credentials.token,
        "subject": "action",
        "action": {
          "name": actionName,
          "params": params,
        },
      });

      const response = await axios({
        method: "post",
        url: credentials.api,
        data: requestData,
      });

      console.log("API CALL REQ:", actionName, params);
      console.log("API CALL RES:", response.data);

      return await successMiddleware(response);
    };

    const token = await api.refreshToken();
    if (token) {
      return callReal();
    }

    return null;
  },
};
