import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Table,
} from "antd";
import moment from "moment";
import React from "react";
import { v4 as uuid } from "uuid";
import { EditableCell, EditableRow } from "./EditableTable";
import { SelectAll } from "./Select";

const stripDate = (strDate, withTime = false) => {
  if (strDate) {
    if (moment.isMoment(strDate)) {
      return strDate.format("YYYY-MM-DD" + (withTime ? " hh:mm" : ""));
    } else {
      return moment()
        .utc(strDate)
        .format("YYYY-MM-DD" + (withTime ? " hh:mm" : ""));
    }
  } else {
    return null;
  }
};

export class ClientView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: props.isClientAddOpen,
      addForm: {
        feature: [],
        featureQty: 1,
        dates: [],
      },
      clientFeature: [...props.clientFeature],
    };

    this.addForm = React.createRef();
  }

  save = (editRow = null) => {
    console.log("SAVE INNER", this.state.clientFeature);

    let features = this.state.clientFeature;

    if (editRow) {
      features = features.filter((entry) => entry.rid === editRow);
    }

    this.props.save(features);
  };

  onAddFinish = (store) => {
    const features = store.feature.map((entry) => {
      return {
        clientRid: this.props.currentClient.rid,
        dBegin: store?.dates?.[0]?.format("YYYY-MM-DD") || "",
        dEnd: store?.dates?.[1]?.format("YYYY-MM-DD") || "",
        featureRid: entry,
        licQty: store.featureQty,
        note: store.note,
        rid: uuid().toUpperCase(),
        usedLicQty: 0,
      };
    });

    const clientFeature = [...this.state.clientFeature, ...features];

    this.setState(
      {
        clientFeature,
      },
      () => {
        this.save();
        this.props.isClientAddOpenChange(false);
      }
    );
  };

  handleSave = (row) => {
    const newData = [...this.state.clientFeature];
    const index = newData.findIndex((item) => row.rid === item.rid);
    console.log("INDEX", index);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState(
      {
        clientFeature: newData,
      },
      () => {
        console.log("try to save", row);
        this.setState({ editRow: row.rid });
        //this.save();
      }
    );
  };

  handleDelete = (rid) => {
    const dataSource = [...this.state.clientFeature];
    this.setState(
      { clientFeature: dataSource.map(item => ({...item, _rowstate: (item.rid === rid ? "d": "s")})) },
      () => {
        this.save();
      }
    );
  };

  handleOk = () => {
    console.log("state", this.addForm);

    if (this.addForm?.current) {
      this.addForm.current.submit();
    }
  };

  handleCancel = () => {
    this.props.isClientAddOpenChange(false);
  };

  getFilteredFeatures = (clientRid) => {
    return this.state.clientFeature.filter((entry, index) => {
      return entry.clientRid === clientRid;
    });
  };

  renderAddLicenses = () => {
    if (!this.state.visible) {
      return null;
    }

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <>
        <Form
          ref={this.addForm}
          initialValues={this.state.addForm}
          onFinish={this.onAddFinish}
          {...formItemLayout}
        >
          <Form.Item name="feature" label="Функции">
            <SelectAll mode="multiple" feature={this.props.feature} />
          </Form.Item>

          <Form.Item name="dates" label="Время действия">
            <DatePicker.RangePicker />
          </Form.Item>

          <Form.Item label="К-во лицензий">
            <Form.Item name="featureQty" noStyle>
              <InputNumber min={-999999} max={999999} />
            </Form.Item>
            <span className="ant-form-text"> шт.</span>
          </Form.Item>

          <Form.Item label="Примечание">
            <Form.Item name="note" noStyle>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Form.Item>
        </Form>
      </>
    );
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
        row: EditableRow,
      },
    };

    const columns = [
      {
        title: "Функция",
        dataIndex: "featureRid",
        key: "featureRid",
        ellipsis: true,
        render: (text) => this.props.feature[text].title,
        sorter: (a, b) => {
          return ("" + this.props.feature[a.featureRid].title).localeCompare(
            this.props.feature[b.featureRid].title
          );
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Дата начала действия",
        dataIndex: "dBegin",
        key: "dBegin",
        ellipsis: true,
        customtype: "date",
        editable: true,
        render: (text) => (text ? stripDate(text) : "∞"),
        sorter: (a, b) => {
          return new Date(b.dBegin) - new Date(a.dBegin);
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Дата окончания действия",
        dataIndex: "dEnd",
        key: "dEnd",
        ellipsis: true,
        customtype: "date",
        editable: true,
        render: (text) => (text ? stripDate(text) : "∞"),
        sorter: (a, b) => {
          return new Date(b.dEnd) - new Date(a.dEnd);
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "К-во лицензий",
        dataIndex: "licQty",
        key: "licQty",
        ellipsis: true,
        editable: true,
        customtype: "number",
        sorter: (a, b) => {
          return ("" + a.licQty).localeCompare(b.licQty);
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Использовано",
        dataIndex: "usedLicQty",
        key: "usedLicQty",
        ellipsis: true,
        sorter: (a, b) => {
          return ("" + a.usedLicQty).localeCompare(b.usedLicQty);
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Создано",
        dataIndex: "dC",
        key: "dC",
        ellipsis: true,
        render: (text) => (text ? stripDate(text, true) : ""),
        sorter: (a, b) => {
          return new Date(b.dC) - new Date(a.dC);
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Изменено",
        dataIndex: "lDM",
        key: "lDM",
        ellipsis: true,
        render: (text) => (text ? stripDate(text, true) : ""),
        sorter: (a, b) => {
          return new Date(b.lDM) - new Date(a.lDM);
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Примечание",
        dataIndex: "note",
        key: "note",
        ellipsis: true,
        editable: true,
        render: (text) => (text ? text : <>&nbsp;</>),
      },
      {
        title: "",
        key: "operation1",
        render: (text, record) => (
          <Button
            type="primary"
            disabled={this.state.editRow !== record.rid}
            onClick={() => {
              this.save(record.rid);
            }}
          >
            Сохранить
          </Button>
        ),
      },
      {
        title: "",
        key: "operation2",
        render: (text, record) => (
          <Popconfirm
            title="Подтвердите удаление"
            onConfirm={() => this.handleDelete(record.rid)}
          >
            <Button type="link">Удалить</Button>
          </Popconfirm>
        ),
      },
    ].map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          customtype: col.customtype,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <>
        <Modal
          maskClosable={false}
          closable={false}
          title="Добавление лицензий"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {this.renderAddLicenses()}
        </Modal>
        <Table
          rowClassName={() => "editable-row"}
          bordered
          components={components}
          title={() => this.props.currentClient?.title}
          scroll={{ y: "calc(100vh - 53px - 55px - 54px)" }}
          rowKey="rid"
          pagination={false}
          dataSource={this.getFilteredFeatures(this.props.currentClient.rid)}
          columns={columns}
        />
      </>
    );
  }
}
