import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import moment from "moment";
import React from "react";
import Highlighter from "react-highlight-words";
import { api } from "../api";
import { get } from "../storage";


const stripDate = (strDate, withTime = false) => {
  if (strDate) {
    if (moment.isMoment(strDate)) {
      return strDate.format("YYYY-MM-DD" + (withTime ? " hh:mm" : ""));
    } else {
      return moment().utc(strDate).format("YYYY-MM-DD" + (withTime ? " hh:mm" : ""));
    }
  } else {
    return null;
  }
};

export class History extends React.Component {
  state = {
    loaded: false,
  }

  componentDidMount() {
    this.getList();
  }

  getList = async () => {
    const response = await api.call("runMobile_ClientFeatureHistoryList", {
      SLRid: await get("SLRid"),
    });

    this.setState({
      clientDevice: response.clientDevice,
      feature: response.feature,
      clientDeviceFeature: response.clientDeviceFeature,
      clientFeature: response.clientFeature,
      loaded: true,
    });
  }

  getColumnSearchProps = (dataIndex, isDate = false) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? (isDate ? stripDate(record[dataIndex], true) : record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()) : <>&bbsp;</>,
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[(this.state.searchText)]}
          autoEscape
          textToHighlight={(isDate ? (stripDate(text, true)) : (text ? text.toString() : <>&bbsp;</>))}
        />
      ) : (isDate ? stripDate(text, true) : text),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    if (!this.state.loaded) {
      return null;
    }

    const columns = [
      {
        title: "Функция",
        dataIndex: "clientFeatureTitle",
        key: "clientFeatureTitle",
        ellipsis: true,
        sorter: (a, b) => { return ("" + a.clientFeatureTitle).localeCompare(b.clientFeatureTitle); },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("clientFeatureTitle"),
      },
      {
        title: "Клиент",
        dataIndex: "clientTitle",
        key: "clientTitle",
        ellipsis: true,
        sorter: (a, b) => { return ("" + a.clientTitle).localeCompare(b.clientTitle); },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("clientTitle"),
      },

      {
        title: "Логин",
        dataIndex: "login",
        key: "login",
        ellipsis: true,
        sorter: (a, b) => { return ("" + a.login).localeCompare(b.login); },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("login"),
      },
      {
        title: "Дата начала действия",
        dataIndex: "dBegin",
        key: "dBegin",
        ellipsis: true,
        customtype: "date",
        editable: true,
        render: (text) => text ? stripDate(text) : "∞",
        sorter: (a, b) => { return new Date(b.dBegin) - new Date(a.dBegin); },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("dBegin", true),
      },
      {
        title: "Дата окончания действия",
        dataIndex: "dEnd",
        key: "dEnd",
        ellipsis: true,
        customtype: "date",
        editable: true,
        render: (text) => text ? stripDate(text) : "∞",
        sorter: (a, b) => { return new Date(b.dEnd) - new Date(a.dEnd); },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("dEnd", true),
      },
      {
        title: "К-во лицензий",
        dataIndex: "licQty",
        key: "licQty",
        ellipsis: true,
        editable: true,
        sorter: (a, b) => { return ("" + a.licQty).localeCompare(b.licQty); },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Использовано",
        dataIndex: "usedLicQty",
        key: "usedLicQty",
        ellipsis: true,
        sorter: (a, b) => { return ("" + a.usedLicQty).localeCompare(b.usedLicQty); },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Дата события",
        dataIndex: "dCH",
        key: "dCH",
        ellipsis: true,
        render: (text) => text ? stripDate(text, true) : "",
        sorter: (a, b) => { return new Date(b.dCH) - new Date(a.dCH); },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("dCH", true),
      },
      {
        title: "Создано",
        dataIndex: "dC",
        key: "dC",
        ellipsis: true,
        render: (text) => text ? stripDate(text, true) : "",
        sorter: (a, b) => { return new Date(b.dC) - new Date(a.dC); },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("dC", true),
      },
      {
        title: "Изменено",
        dataIndex: "lDM",
        key: "lDM",
        ellipsis: true,
        render: (text) => text ? stripDate(text, true) : "",
        sorter: (a, b) => { return new Date(b.lDM) - new Date(a.lDM); },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("lDM", true),
      },
      {
        title: "Статус",
        dataIndex: "state",
        key: "state",
        ellipsis: true,
        render: (text) => text,
        sorter: (a, b) => { return ("" + a.state).localeCompare(b.state); },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps("state"),
      },
      {
        title: "Примечание",
        dataIndex: "note",
        key: "note",
        ellipsis: true,
        editable: true,
        render: (text) => text ? text : <>&nbsp;</>,
        ...this.getColumnSearchProps("note"),
      },
    ];

    return <>
      <Table
        size="small"
        rowKey="rid"
        dataSource={this.state.clientFeature}
        columns={columns}
      />
    </>;
  }
}
